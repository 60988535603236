

























































































import { Component } from 'vue-property-decorator';
import Vue from 'vue';
import './scss/InputControl.scss';
import { namespace } from 'vuex-class';
import { IInputControlDocument, IInputControlDocumentQuality } from '@store/modules/input-control/Interfaces';
import { ITask } from "@store/modules/project-tasks/interfaces/Interfaces";
import { ProjectTasksActions } from "@store/modules/project-tasks/Types";
import { IAddTaskPostData } from "@store/modules/project-tasks/interfaces/ApiRequests";

const NSInputControl = namespace('storeInputControl');
const NSProjectTasks = namespace('storeProjectTasks');
const NSProject = namespace('storeProject');

@Component({
    name: 'InputControl',
    components: {
        BaseGroup: () => import('@components/BaseGroup/BaseGroup.vue'),
        InputControlRow: () => import('./components/InputControlRow.vue'),
        InputControlActions: () => import('./components/InputControlActions.vue'),
        BasePreloader: () => import('@components/BasePreloader/BasePreloader.vue'),
        PopupInputControlDocs: () => import('@components/popups/PopupInputControlDocs.vue'),
        PopupFilePreview: () => import('@components/popups/PopupFilePreview.vue'),
        PopupTasksList: () => import('@components/popups/PopupTasksList.vue'),
        PopupCreateTask: () => import('@components/popups/PopupCreateTask.vue'),
        PopupConfirmation: () => import('@components/popups/PopupConfirmation.vue'),
        PopupTaskDetails: () => import('@components/popups/PopupTaskDetails.vue'),
    }
})

export default class InputControl extends Vue {
    $refs!: {
        docsPopup: HTMLFormElement,
        filePreviewPopup: HTMLFormElement,
        popupTasksList: HTMLFormElement,
        popupCreateTask: HTMLFormElement,
        popupSuccessNotification: HTMLFormElement,
        popupTaskDetails: HTMLFormElement,
        popupConfirmRemoveSubtask: HTMLFormElement,
    }

    @NSProjectTasks.Getter('inputControlDocumentTasks') inputControlDocumentTasks!: ITask[];
    @NSProjectTasks.Action(ProjectTasksActions.A_PROJECT_STORE_LISTENER)
    getProjectTasks!: (projectId: number) => Promise<void>;
    @NSProjectTasks.Action(ProjectTasksActions.A_CREATE_NEW_TASK)
    sendAddTaskFormAction!: ({postData: IAddTaskPostData}) => Promise<any>
    @NSProjectTasks.Action(ProjectTasksActions.A_DELETE_TASK)
    deleteTask!: (payload: { appId: string | number, ids: string[] }) => Promise<void>
    @NSInputControl.Getter('inputControlDocuments') inputControlDocuments!: IInputControlDocument[];
    @NSInputControl.Getter('inputControlDocumentsNoQualityDocs') inputControlDocumentsNoQualityDocs!: IInputControlDocument[];
    @NSInputControl.Getter('inputControlLoaded') inputControlLoaded!: boolean;
    @NSProject.Getter('projectId') projectId!: number;

    controlGroups: any[] = [
        {
            title: 'Материалы без документов качества',
            type: 'withoutQualityDocuments',
            flags: [
                {
                    color: '#F56E6E',
                    text: 'Важно',
                }
            ],
        }, {
            title: 'Поступление материалов',
            type: 'withQualityDocuments',
            flags: [],
        }
    ]

    selectedItems: string[] = [];
    qualityDocuments: IInputControlDocumentQuality[] = [];
    selectedInputControlDocumentNoQualityDocs = {} as IInputControlDocument;
    selectedTaskToView = {} as ITask;
    popupSuccessNotificationTitle: string = '';
    popupSuccessNotificationText: string = '';
    popupSuccessNotificationOpen: boolean = false;
    showSuccessNotificationPopupDuration: number = 2500;
    popupCreateTaskOpen: boolean = false;
    popupTaskDetailsOpen: boolean = false;
    timeForReloadSubtasksList: number = 500;
    selectedSubtask = {} as ITask;
    deleteConfirmationTextSubtask: string = '';
    deleteSubtaskPopupConfirmationOpen: boolean = false;
    waitingSaveSubtask: boolean = false;

    get taskListOfSelectedInputControlDocumentNoQualityDocs(): ITask[] {
        return this.inputControlDocumentTasks.filter((inputControlDocumentTask: ITask) => {
            return inputControlDocumentTask.subject?.uuid === this.selectedInputControlDocumentNoQualityDocs.uuid;
        });
    }

    get escKeyPopupTaskListDisabled(): boolean {
        return this.popupCreateTaskOpen || this.popupTaskDetailsOpen;
    }

    get newTaskDetailsSliderInfo(): ITask {
        const selectedTaskId: string = this.selectedTaskToView.uuid;
        return this.inputControlDocumentTasks.filter((task) => task.uuid === selectedTaskId)[0];
    }

    addSubtask(taskFormData: IAddTaskPostData): void {
        this.waitingSaveSubtask = true;
        this.sendAddTaskFormAction({ postData: taskFormData })
            .then(() => {
                setTimeout(() => {
                    this.updateTaskDetailsSlider();
                }, this.timeForReloadSubtasksList + 100);
                this.successNotificationPopupShow('Добавление подзадачи', 'Подзадача добавлена');
            })
            .finally(() => {this.waitingSaveSubtask = false;});
    }

    deleteSubtaskOpenConfirmPopup(subtask: ITask): void {
        this.selectedSubtask = subtask;
        this.deleteConfirmationTextSubtask = `Вы уверены, что хотите удалить подзадачу "${subtask.title}"?`;
        this.deleteSubtaskPopupConfirmationOpen = true;
    }

    onConfirmDeleteSubtask(): void {
        this.deleteSubtaskPopupConfirmationOpen = false;
        this.deleteSubtask();
    }

    deleteSubtask(): void {
        this.deleteTask({
            appId: this.projectId,
            ids: [this.selectedSubtask.uuid],
        }).then(() => {
            this.updateTaskDetailsSlider();
            this.successNotificationPopupShow('Удаление подзадачи', `Подзадача "${this.selectedSubtask.title}" успешно удалена`);
        })
    }

    updateTaskDetailsSlider(): void {
        setTimeout(() => {
            this.getProjectTasks(this.projectId).then(() => {
                this.selectedTaskToView = this.newTaskDetailsSliderInfo;
            });
        }, this.timeForReloadSubtasksList + 100);
    }

    openPopupTaskList(inputControlDocumentNoQualityDocs: IInputControlDocument): void {
        this.closeOtherPopup('popupTasksList');
        this.selectedInputControlDocumentNoQualityDocs = inputControlDocumentNoQualityDocs;
        this.$refs.popupTasksList.setTasksList(this.taskListOfSelectedInputControlDocumentNoQualityDocs);
        this.$refs.popupTasksList.openPopup();
    }

    countTasks(inputControlDocumentNoQualityDocs: IInputControlDocument): number {
        return this.inputControlDocumentTasks.filter((task: ITask) => {
            return task.subject
                && task.subject.uuid
                && task.subject.uuid === inputControlDocumentNoQualityDocs.uuid;
        }).length;
    }

    openPopupCreateTask(inputControlDocumentNoQualityDocs?: IInputControlDocument): void {
        this.closeOtherPopup('popupCreateTask');
        if (inputControlDocumentNoQualityDocs) {
            this.selectedInputControlDocumentNoQualityDocs = inputControlDocumentNoQualityDocs;
        }
        this.popupCreateTaskOpen = true;
        this.$refs.popupCreateTask.setTitle(this.selectedInputControlDocumentNoQualityDocs.title);
        this.$refs.popupCreateTask.openPopup();
    }

    openDocsPopup(e) {
        this.$refs.docsPopup.openPopup();
        this.setQualityDocuments(e);
    }

    openFilePreviewPopup(data) {
        this.$refs.filePreviewPopup.setData(data);
        this.$refs.filePreviewPopup.openPopup();
    }

    openPopupTaskDetails(task: ITask): void {
        this.selectedTaskToView = task;
        this.popupTaskDetailsOpen = true;
    }

    closePopupCreateTask(): void {
        this.popupCreateTaskOpen = false;
    }

    closePopupConfirmation(): void {
        this.popupSuccessNotificationOpen = false;
    }

    closeOtherPopup(currentPopupNameRef: string): void {
        for (let popup in this.$refs) {
            if (currentPopupNameRef !== popup) {
                this.$refs[popup]?.$refs?.basePopup.closePopup();
            }
        }
    }

    confirmCreateTask(): void {
        let titleInputCtrlDoc: string = this.selectedInputControlDocumentNoQualityDocs?.title
            ? this.selectedInputControlDocumentNoQualityDocs.title
            : '';
        this.successNotificationPopupShow(
            'Задача добавлена',
            `Задача для материала ${titleInputCtrlDoc} успешно создана`,
        );
        this.reloadStoreTasks();
    }

    reloadStoreTasks() {
        this.getProjectTasks(this.projectId)
            .then(() => {
                this.$refs.popupTasksList.setTasksList(this.taskListOfSelectedInputControlDocumentNoQualityDocs);
            });
    }

    successNotificationPopupShow(title: string, text: string): void {
        this.popupSuccessNotificationTitle = title;
        this.popupSuccessNotificationText = text;
        this.popupSuccessNotificationOpen = true;

        setTimeout(() => {
            this.successNotificationPopupReset();
        }, this.showSuccessNotificationPopupDuration);
    }

    successNotificationPopupReset(): void {
        this.popupSuccessNotificationTitle = '';
        this.popupSuccessNotificationText = '';
        this.popupSuccessNotificationOpen = false;
    }

    setQualityDocuments(val: IInputControlDocumentQuality[]) {
        this.qualityDocuments = val;
    }

    clearQualityDocuments() {
        this.qualityDocuments = [];
    }

    onSelectItem(e) {
        if (this.selectedItems.indexOf(e.uuid) === -1) {
            this.selectedItems.push(e.uuid);
        } else {
            this.selectedItems.splice(this.selectedItems.indexOf(e.uuid), 1);
        }
    }
}
